import React from "react";

const SkillCard = ({ skillName, skillIcon }) => {
  return (
    <div className="m-4 w-40 flex-none mx-auto text-center py-5 px-2 rounded-xl border-2 text-indigo-200 border-indigo-300 bg-gradient-to-r from-blue-400 to-blue-600 shadow-2xl">
      {skillIcon}
      <p className="text-xl font-semibold mt-4 text-white">{skillName}</p>
    </div>
  );
};

export default SkillCard;
