import React from 'react'
import GitHubButton from 'react-github-btn'

function Footer({github}) {
  return (
    <div className="text-center w-full mt-16">
      <p className="text-gray-200 mb-4">
        Made with <span role="img" aria-label="heart">💙</span> by <a className="text-blue-100 hover:underline" href="https://github.com/treeskiii">Treeski</a>
      </p>
    </div>
  )
}

export default Footer