import React from "react";
import { FaFigma, FaReact, FaCode } from "react-icons/fa";

const BOOTSTRAP_FOR_SKILL_ICON = "text-4xl mx-auto inline-block";
const data = {
  name: "Mat P.",
  title: "Digital Designer, UI/UX, 3D, Web3",
  social: {
    github: "https://github.com/treeskiii",
    dribbble: "https://github.com/mpio222",
    twitter: "https://github.com/treeski_",
    email: "mpio222@gmail.com",
  },
  about: {
    title: "My Background",
    description:
      "Digital Designer specializing in UX and UI design with hands-on experience in content creation for high-impact web and mobile applications. Entrepreneurial, self motivated, and skilled in scaling applications through collaboration with designers, developers, product managers, and marketing teams. ",
  },
  skills: [
    {
      skillName: "Junior Dev Skill Level",
      skillIcon: <FaCode className={BOOTSTRAP_FOR_SKILL_ICON} />,
    },
    {
      skillName: "Figma, Adobe Creative Cloud",
      skillIcon: <FaFigma className={BOOTSTRAP_FOR_SKILL_ICON} />,
    },
    {
      skillName: "Always Learning",
      skillIcon: <FaReact className={BOOTSTRAP_FOR_SKILL_ICON} />,
    },
  ],
  projects: [
    {
      title: "SpaceRacers",
      description: "SpaceRacers is a collection of 5,555 custom designed 3D Alien Racer NFT's.",
      tags: [
        "Web3",
        "NFT",
        "reactjs",
        "tailwindcss",
        "HTML5",
        "Solidity"
      ],
      link: "https://spaceracers.io/"
    },
    {
      title: "Wayfinder Finance",
      description: "Wayfinder is a project dedicated to creating a friendly ecosystem for cryptocurrency investment and unlocking cross chain potential. ",
      tags: [
        "React",
        "NextJS",
        "tailwind",
        "tailwindcss",
        "vercel"
      ],
      link: "https://wayfinder.finance/"
    },
    {
      title: "Piolabs",
      description: "⚡ My Digital Design Company, built with NextJS and TailwindCSS.",
      tags: [
        "website",
        "tailwind",
        "nextjs",
        "tailwindcss",
        "vercel"
      ],
      link: "https://www.piolabs.io/"
    }
  ]
};
export default data;
