import React from "react";

function About({ title, description }) {
  return (
    <div className="max-w-4xl mx-auto mt-16    ">
      <p className="text-2xl md:text-4xl text-center font-extrabold text-transparent text-8xl bg-clip-text bg-gradient-to-r from-purple-200 to-blue-400   ">{title}</p>
      <p className=" text-base text-left md:text-center text-gray-300 leading-relaxed mt-4  p-4 rounded-xl bg-opacity-90 bg-gradient-to-r from-blue-500 to-blue-800 shadow-2xl border-2 border-indigo-300 ">
        {description}
      </p>
    </div>
  );
}

export default About;
